import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, FigureDouble, List,
} from '../../components/opComponents';

const KysteDuPoignet = () => (
  <Layout
    title="Kyste du poignet : Diagnostic et traitement"
    description="Le kyste du poignet est une tuméfaction bénigne, remplie d'un liquide gélatineux produit par la membrane synoviale qui tapisse les articulations."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Kyste du poignet" />
        <MainPart title="Qu'est-ce qu'un kyste du poignet ?">
          <Typography weight="bold">
            Le kyste du poignet est une tuméfaction bénigne,
            {' '}
            <Typography variant="span">
              remplie d&apos;un liquide gélatineux produit par la
              membrane synoviale qui tapisse les articulations.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Où sont situés les kystes du poignet ?">
          <Typography>
            Les kystes peuvent être situés sur la face palmaire
            (« kyste de la gouttière du pouls ») ou sur la face
            dorsale du poignet (« kyste dorsal »).
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les causes possibles d'un kyste du poignet ?">
          <Typography>
            Les kystes du poignet sont d&apos;origine articulaire,
            en rapport le plus souvent, avec une détérioration
            de la capsule (« dégénérescence mucoïde »). Parfois,
            ils peuvent témoigner d&apos;une souffrance plus importante
            du poignet (rupture ligamentaire, arthrose etc).
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les symptômes d'un kyste du poignet ?">
          <div className="flex flex-col">
            <Typography>
              Le kyste forme une masse plus ou moins tendue, mobile et parfois douloureuse à
              l&apos;appui ou dans certains mouvements du poignet. Son apparition peut être
              brutale et douloureuse (suite à un traumatisme du poignet) ou au
              contraire progressive et indolore.
            </Typography>
            <Typography>
              Le kyste peut être responsable d&apos;une gêne esthétique
              et/ou fonctionnelle selon sa localisation et sa taille.
            </Typography>
          </div>
          <FigureDouble
            maxWidth="max-w-[400px]"
            height="h-[300px]"
            imageNameOne="figure1-kystedepoignet.jpg"
            imageNameTwo="figure1bis-kystedepoignet.jpg"
            caption="Figure 1. Volumineux kyste dorsal du poignet responsable d&apos;une gêne esthétique et fonctionnelle"
            currentOpTitle="Kyste du poignet"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui confirment un kyste du poignet ?">
          <Typography>
            La réalisation d&apos;une radiographie et d&apos;une échographie du poignet,
            permet de confirmer le diagnostic de kyste et de rechercher une
            éventuelle lésion sous-jacente.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux pour traiter un kyste du poignet ?">
          <Typography size="mdx" weight="bold">Médical :</Typography>
          <Typography weight="bold">
            20 à 25% des kystes du poignet disparaissent spontanément dans les 6 mois
            {' '}
            <Typography variant="span">
              qui suivent leur apparition.
              Le port d&apos;une attelle nocturne permet, le plus souvent, de diminuer la taille
              du kyste. La ponction du kyste associée à une infiltration de corticoïde peut
              être envisagée dans un premier temps mais les récidives sont très fréquentes.
              Il est fortement déconseillé d&apos;écraser le kyste,
              car il s&apos;agit d&apos;un geste
              très douloureux et souvent peu efficace.
            </Typography>
          </Typography>
          <Typography size="mdx" weight="bold">Chirurgicale :</Typography>
          <Typography weight="bold">
            La chirurgie est justifiée en cas kyste présent depuis plus
            de 3 mois, responsable d&apos;une gêne esthétique et/ou fonctionnelle.
            {' '}
            <Typography variant="span">
              L&apos;intervention est réalisée sous anesthésie
              locorégionale (seul le bras est endormi),
              en ambulatoire (pas d&apos;hospitalisation). Le principe étant de retirer la
              totalité du kyste et de sa base d&apos;implantation.
              Elle peut être réalisée de deux manières :
            </Typography>
          </Typography>
          <List>
            <List.Item>
              <Typography>
                sous arthroscopie (cf.chapitre spécifique) : deux petites incisons
                sont réalisées au niveau du poignet pour l&apos;introduction d&apos;une caméra
                et d&apos;un instrument miniaturisés. Cette technique permet de retirer
                le kyste par l&apos;intérieur du poignet et de traiter une éventuelle lésion
                articulaire responsable de l&apos;apparition du kyste. Les séquelles esthétiques
                (petites cicatrices) et fonctionnelles (peu de raideur) sont moins
                importantes par rapport à la chirurgie conventionnelle à ciel ouvert.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                à ciel ouvert : en cas de kyste difficile d&apos;accès par arthroscopie,
                une incision en regard du kyste permet son extraction.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quelle est la suite post-opératoire après une opération chirurgicale pour un kyste du poignet ?">
          <Typography>
            Un petit pansement est réalisé tous les 2-3 jours pendant 14 jours. La reprise
            de la conduite est possible dès la 2ème semaine post-opératoire. La
            rééducation post-opératoire peut être nécessaire en cas de raideur du poignet.
            La reprise d&apos;une activité sportive ou manuelle intensive est possible
            dès la fin du premier mois post-opératoire.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour un kyste du poignet ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de complications
            secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Récidive du kyste (10% des cas), est la complication la plus fréquente
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hernie synoviale, correspondant à l&apos;apparition d&apos;un petit kyste sur les
                cicatrices d&apos;arthroscopie. Elle disparaît la plupart du temps
                spontanément au bout de quelques semaines.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Cicatrice inesthétique
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Douleurs du poignet : en rapport avec une inflammation locale ou irritation
                des branches cutanées nerveuses. Elles disparaissent au bout de quelques semaines.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Raideur de poignet, nécessitant des séances de rééducation
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default KysteDuPoignet;
